/* .listTable .ant-table-tbody > tr > td,
.listTable .ant-table-thead th {
  border: none !important;
} */

  /* .listTable{
    overflow-x: scroll;
  } */

.listTable .ant-table-thead th {
  position: relative;
}

.listTable .ant-table-thead th::after {
  /* content: ""; */
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  z-index: 10;
  background-color: lightgray;
}

.listTable .ant-table-thead th:first-of-type::after {
  left: 20px;
}

.listTable .ant-table-thead th:last-of-type:after {
  width: calc(100% - 20px);
}

.OrderID {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonArea {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.fontStyle1{
    color: grey;
    font-size: 15px;
    font-weight: 500;
    min-width: 100px;
    text-align: start;
}
.fontStyle2{
    color: grey;
    font-size: 15px;
    font-weight: 500;
    min-width: 118px;
    text-align: start;
}
.fontStyle3{
    color: grey;
    font-size: 15px;
    font-weight: 500;
    min-width: 150px;
    text-align: start;
}
.fontStyle4{
    color: grey;
    font-size: 15px;
    font-weight: 500;
    min-width: 150px;
    text-align: start;
}
.fontStyle5{
    color: grey;
    font-size: 15px;
    font-weight: 500;
    min-width: 120px;
    text-align: start;
}
.fontStyle6{
    color: grey;
    font-size: 15px;
    font-weight: 500;
    min-width: 100px;
    text-align: start;
}

.detailsArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.addressArea{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.customerDetails{
    display: flex;
    justify-content: center;
    align-items: center;
}


.rowArea{
    width: 400px;
}

.status-select.ant-select-selector {
  padding:0 !important;
  border: none !important;
  box-shadow: none !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  padding:0 !important;
  
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0px !important;
}
.status-select .ant-select-selector {
  padding-right: 1px !important;
}

.order-list-filter-container{
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.order-list-filter-form{
  display: flex;
  column-gap: 10px;
  margin-bottom: 17px;
}

.order-list-filter-form .ant-form-item:nth-child(1) .ant-select{
  max-width: 300px;
}

.oreder_frame{
  background-color: #a2c57980;
  border-radius: 5px;
  padding: 5px;
  /* color: white  !; */
}

.order_preview{
  padding: 10px;
  height: 600px !important;
}

.order_preview_box{
  height: 100vh !important;
  overflow-y: scroll;
}

.order_preview .ant-modal-content .ant-modal-close{
  top: -2%;
  left: 95%;
}

@media screen and (min-width: 768px) {
  .orderIdSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2%;
  }
  .buttonArea {
    display: flex !important;
    justify-content: space-between !important;
    width: 250px !important;
  }
  .detailsArea{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}
.fontStyle{
    color: grey;
    font-size: 15px;
    font-weight: 500;
    min-width: 100px;
}
.rowArea{
    width: 100%;
}
.addressArea{
display: flex;
justify-content: flex-start;
margin-left: 10px;
text-align: left !important;
}
}


@media (min-width: 576px) and (max-width: 720px) {
  .order-list-filter-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .order-list-filter-form{
    display: flex;
  }
  
}

@media  (min-width: 320px) and (max-width: 490px){
  .order-list-filter-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-page-header-heading-extra{
    width: 100%;
  }
  .order-list-filter-form{
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .order-list-filter-form .ant-form-item .ant-select{
    width: 100% ;
  }

  .ant-page-header-heading{
    display: flex;
    flex-direction: column !important;
  }
}
.order_back_button{
  font-size: 25px;
}
.order_back_button:hover{
  color: #4EA144;
}
.Order_show .ant-modal-footer{
  display: none;
}
.quantity_input{
  width: 41px;
  border-radius: 100px;
}
.quantity_input .ant-input-number-input{
 text-align: center !important;
 padding: 0px !important;
}
.quantity_increament_button{
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #4EA144;
  color: aliceblue;
  margin: 0px 10px 0px 10px;
}
.quantity_decreament_button{
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #4EA144;
  color: aliceblue;
  margin: 0px 10px 0px 0px;
}
.orderCreate_back_button{
  font-size: 20px;
}
.orderCreate_back_button :hover{
  color: #4EA144;
}
.ordercheckout_table{
  height: 330px;
  overflow-y: scroll;
}
.order_header_container_box_total{
  display: flex;
  justify-content:space-between;
  margin-inline-end: 18px;
  margin-top: 10px;
}

.order_alert_box{
  width: 50%;
  text-align: justify;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
}
.order_alert_content{
  width: 100%;
}
.order_alert_content h5{
  font-size: 18px;
  color: #4EA144;
}
.order_content_head{
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
}

.alert_btn{
  display: flex;
  column-gap: 10px;
}

.ant-typography-edit-content {
  left: 0% !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.alert_mgs{
  width: 100%;
  font-size: 14px;
  font-family: inherit;
  max-height: 200px; 
  overflow-y: auto;  
  padding: 5px;
  scrollbar-width: thin; 
  scrollbar-color: #888 #f1f1f1;
}

.alert_mgs::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  margin: 5px; /* This is generally not supported, see note below */
}

.alert_mgs::-webkit-scrollbar-track {
  background: transparent; /* Transparent background */
  border-left: 10px solid transparent; /* Simulate margin-left */
}

.alert_mgs::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px;     /* Rounded edges */
  border: 2px solid #f1f1f1; /* To simulate a small thumb size */
}

.alert_mgs::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darken the thumb on hover */
}

.alert_mgs .ant-input{
  overflow-y: clip;
}

.edit_btn, .tick_btn{
  font-weight: 500;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 17%) 0px 1px 4px;
}

.alert_btn .edit_btn{
  color: #4EA144;
}

.alert_btn .tick_btn{
  color: red;
}

.alert{
  justify-content: flex-end !important;
}

.fav_Modal_footer{
  display: flex;
  width: 100% ;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.fav-btn_save{
  width: 100px;
  border-radius: 5px;
}

.fav-btn_cancel{
  border-radius: 5px;
  width: 100px;
  background-color: red;
  color: white;
}

.fav-btn_cancel:hover{
  background-color: red !important;
  color: white !important;
}

.fav_modal_content{
  display: flex;
  justify-content: center;
}

.fav_modal_content p{
  font-size: 16px;
  font-weight: 500;
}

.ant-checkbox-wrapper{
  margin-left:0px !important ;
}

.select-dropdown-custom .ant-select-item-option-content {
  user-select: text; /* Allow text selection */
}

.custome_selector{
  min-width: 200px;
  max-width: 100%;
}

.btn-del{
  color: red;
  font-size: 20px;
  margin-top: 10px;
}

.drop_list{
  display: flex;
  justify-content: space-between;
}

.content-align{
  margin:auto;
  margin-bottom: 10px;
}
.content-row:nth-child(odd){
  background-color: #a2c57980;
  margin-top: 5px ;
  margin-bottom: 5px ;
  color: black;
  width: 100%;
}

.ant-table-tbody > .content-row:nth-child(odd) >td.ant-table-cell-row-hover{
  background-color: #a2c57980 !important; 
}

.customer_box{
  margin-top: 10px;
  margin-bottom: 10px;
}

.cusBox_color{
  background-color: #ffd;
  border-radius: 5px;
  padding: 10px;
}